body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h2 {
    text-align: left;
}

h4 {
    text-align: left;
}

h5 {
    color: #3a3b3f;
    font-weight: normal;
    font-size: 1.2em;
    margin-block-start: 0.8em;
}
